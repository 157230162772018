import * as L from 'leaflet';
import $ from 'jquery';
import { DateTime } from "luxon";
import { CoordinatesHelper } from '../helpers/CoordinatesHelper';

export class WeatherEventProvider {
    constructor(connectionString) {
        this.connectionString = connectionString;
    }

    getAllAsGeoJsonByProjectId(projectId, start, end) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `${this.connectionString}/api/WeatherEvent/GetAllAsGeoJsonByProjectIdAndRange`,
                type: 'GET',
                data: {
                    projectId: projectId,
                    start: start,
                    end: end
                },
                success: (result) => {
                    resolve(JSON.parse(result));
                },
                error: (error) => {
                    reject(error);
                }
            });
        })
    }

    
    createTimelineItems(data) {
        let dataBuffer = [];
        data.features.forEach(function (feature) {
            if (dataBuffer.find(x => x.id === feature.properties.featureId)){
                return
            }

            let dateTime = DateTime.fromMillis(feature.properties.time[0]);
            dataBuffer.push({
                id: feature.properties.featureId,
                title: `Weather Event`,
                content: `Weather Event`,
                start: dateTime.toLocal().toRFC2822(),
                end: dateTime.toLocal().toRFC2822(),
                group: feature.properties.featureType,
                type: 'box'
            });
        });

        return dataBuffer;
    }
    
    static getMarkerOptions(feature) {

        let coords = CoordinatesHelper.latLngtoDecimalDegreesMinutesString(feature.geometry.coordinates[1], feature.geometry.coordinates[0])
        return {
            icon: L.divIcon({
                html:
                    `<svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.01 28.81">
                    <g id="Icon">
                        <path d="m35.95,10.85c-.33,0-.66.02-.98.06l-.36.04-.05-.36c-.81-6.03-6.01-10.58-12.09-10.58s-11.22,4.5-12.08,10.48l-.05.36-.36-.04c-.3-.03-.6-.04-.9-.04C4.07,10.75,0,14.82,0,19.82s3.8,8.76,8.69,8.94l.32.05,16.44-.02,1.55-3.16H8.91c-1.52-.04-2.94-.67-4-1.75-.26-.27-.49-.55-.69-.85-.21-.33-.38-.65-.52-.97-.15-.38-.26-.73-.33-1.08-.08-.4-.12-.79-.12-1.16,0-3.21,2.61-5.82,5.82-5.82.35,0,.71.04,1.08.1,1.62.3,3.04,1.32,3.9,2.74l2.7-1.85c-.76-1.21-1.81-2.23-3.05-2.97l-.2-.12v-.23c.29-4.73,4.22-8.44,8.95-8.44s8.75,3.78,8.95,8.6v.13c.01.08.02.15.02.23,0,1.27-.27,2.51-.82,3.69l2.96,1.37c.41-.89.71-1.81.88-2.75l.05-.25.24-.05c.4-.08.81-.13,1.21-.13,3.21,0,5.82,2.61,5.82,5.82,0,.2-.01.4-.03.59,0,.06-.02.12-.03.18l-.03.17c-.01.08-.02.15-.04.23-.01.07-.03.14-.05.21l-.04.15c-.01.06-.03.13-.05.19-.03.1-.06.18-.1.27l-.04.12s-.03.1-.05.14c-.05.13-.12.25-.18.37l-.07.14c-.07.14-.16.27-.24.4-.14.21-.24.34-.34.46-.16.2-.28.33-.41.46-1.1,1.11-2.46,1.64-4.01,1.65h-5.47l-1.6,3.13h7.28c4.9-.18,8.74-3.97,8.74-8.87s-4.07-9.07-9.07-9.07Z"/>
                    </g>
                </svg>
                `,
                className: "rotated-marker",
                style: "transform-origin: center;",
                iconSize: [25, 50], // size of the icon
                iconAnchor: [13, 11], // point of the icon which will correspond to marker's location
                popupAnchor: [0, -10] // point from which the popup should open relative to the iconAnchor
            }),
            popupContent: `<b>Weather Event ${DateTime.fromMillis(feature.properties.time[0], { zone: 'utc'}).toLocal().toLocaleString(DateTime.DATETIME_SHORT)}</b><br>
                           <b>Wind Strength:</b> ${feature.properties.windStrength}<br>
                           <b>Wind Direction:</b> ${feature.properties.windDirection}<br>
                           <b>State:</b> ${feature.properties.seaState}<br>
                           <b>Swell:</b> ${feature.properties.seaSwell}<br>
                           <b>Visibility:</b> ${feature.properties.seaVisibility}<br>
                           <b>Logged By:</b> <a href="https://www.marinetraffic.com/en/ais/details/ships/${feature.properties.loggedByMmsi}" target="_blank">${feature.properties.loggedByMmsi}</a><br>
                           <b>Date:</b> ${DateTime.fromMillis(feature.properties.time[0], { zone: 'utc' }).toLocal().toLocaleString(DateTime.DATETIME_SHORT)}<br>
                           <b>Coordinates:</b>
                           <br>
                           <ul>
                                <li>
                                    <b>Latitude:</b> ${coords.lat}
                                </li>
                                <li>
                                    <b>Longitude:</b> ${coords.lng}
                                </li>
                            </ul>`,
            tooltipContent: `Weather Event`
        };    
    }
}