import $ from 'jquery';

export class ProjectCentroidProvider {
    constructor(connectionString) {
        this.connectionString = connectionString;
    }

    getCentroid(projectId) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `${this.connectionString}/api/Project/GetCentroidCoordinate`,
                type: 'GET',
                data: {
                    id: projectId
                },
                success: (result) => {
                    resolve(JSON.parse(result));
                },
                error: (error) => {
                    reject(error);
                }
            });
        })
    }
}