export class CoordinatesHelper{
    static latLngtoDecimalDegreesMinutesString(latitude, longitude){

        var latDirection = latitude < 0 ? 'S' : 'N';
        var lngDirection = longitude < 0 ? 'W' : 'E';

        var latInDegrees = Math.floor(Math.abs(latitude));
        var lngInDegrees = Math.floor(Math.abs(longitude));


        var latDecimal = Math.abs(latitude) - latInDegrees;
        var lngDecimal = Math.abs(longitude) - lngInDegrees;

        var precision = 3;

        var latMinutes = (latDecimal * 60).toFixed(precision);
        var lngMinutes = (lngDecimal * 60).toFixed(precision);

        var latCoords = latInDegrees + "°" + latMinutes + "'" + latDirection;
        var lngCoords = lngInDegrees + "°" + lngMinutes + "'" + lngDirection;

        var coords = {
            lat: latCoords,
            lng: lngCoords
        }

        return coords;
    }
}