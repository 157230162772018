import { LeafletMapTimeline } from "/src/app/LeafletMapTimeline";
import $ from 'jquery';
import { Modal } from 'bootstrap';
import Swal from 'sweetalert2'
import toastr from 'toastr'

let leafletMapTimeLine = null;

window.TriggerFileUpload = (id) => {
    var fileInput = document.getElementById(id);
    fileInput.click();
}

window.SetAElement = (url, id) => {
    var element = document.getElementById(id)
    element.href = url;
}

window.triggerFileDownload = (fileName, url) => {
    const anchorElement = document.createElement('a');
    anchorElement.href = url;
    anchorElement.download = fileName ?? '';
    anchorElement.click();
    anchorElement.remove();
}
window.resizeTextareaFromEvent = (id) => {
    var textarea = document.getElementById(id);
    if (textarea) {
        textarea.style.height = 'auto'; // Reset height to calculate the new height
        textarea.style.height = textarea.scrollHeight + 'px';
    }
};
window.downloadFileFromStream = async (fileName, contentStreamReference) => {
    const arrayBuffer = await contentStreamReference.arrayBuffer();
    const blob = new Blob([arrayBuffer]);
    const url = URL.createObjectURL(blob);
    const anchorElement = document.createElement('a');
    anchorElement.href = url;
    anchorElement.download = fileName ?? '';
    anchorElement.click();
    anchorElement.remove();
    URL.revokeObjectURL(url);
}

window.ScrollToElementId = (elementId) => {
    var element = document.getElementById(elementId);
    if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
};

window.CreateMapTimelineWithProjectId = (connectionString, projectId) => {
    leafletMapTimeLine = new LeafletMapTimeline(connectionString);
    leafletMapTimeLine.initWithProjectId(projectId);
}

window.MapTimelineWithGoToFeatureTimestamp = (featureId, timestamp, featureType) => {
    if (leafletMapTimeLine) {
        leafletMapTimeLine.goToFeatureTimestamp(featureId, timestamp, featureType);
    }
}

window.ShowToastr = (type, message) => {
    if (type === 'success') {
        toastr.success(message, 'Operation Successful', { timeOut: 5000 })
    }

    if (type === 'error') {
        toastr.error(message, 'Operation Failed', { timeOut: 5000 })
    }
}

window.enableTooltips = () => {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    console.log('tooltips are being enabled')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
}

window.ShowSwal = (type, title, message) => {
    if (type === 'success' || type == 'error') {
        Swal.fire(
            title,
            message,
            type
        )
    }
}

window.ShowSendEmailPopupModal = () => {
    let element = document.querySelector('#EmailPopupModal')
    let modal = Modal.getOrCreateInstance(element)
    modal.show();
}

window.HideSendEmailPopupModal = () => {
    let element = document.querySelector('#EmailPopupModal')
    let modal = Modal.getOrCreateInstance(element)
    modal.hide();
    $('body').removeClass('modal-open');
    $('body').removeAttr('style');
}


window.showModal = function (dialogId) {
    let dialog = document.getElementById(dialogId);
    if (dialog) {
       var modal = new bootstrap.Modal(dialog);
        modal.show();
    }
};

window.hideModal = function (dialogId) {
    let dialog = document.getElementById(dialogId);
    if (dialog) {
        var modal = bootstrap.Modal.getInstance(document.getElementById(dialogId));
        modal.hide();
    }
};
window.animateSvgProgress = function (elementId, endValue) {
    const element = document.getElementById(elementId);
    let currentValue = 0;
    const step = endValue / 100;

    const interval = setInterval(() => {
        currentValue += step;
        element.setAttribute("stroke-dasharray", `${currentValue} 628.32`);

        if (currentValue >= endValue) {
            clearInterval(interval);
        }
    }, 10); // Adjust the interval for speed
};

window.dialogShow = (dialogId, isModal = false) => {
    setTimeout(() => {
        let dialog = document.getElementById(dialogId);
        if (dialog == null) {
            console.error(`Dialog with id ${dialogId} not found`);
            return
        }
        !isModal ? dialog.show() : dialog.showModal();
        $('body').css("overflow", "hidden");
    }, 100);
}

window.dialogHide = (dialogId) => {
    return new Promise((resolve, reject) => {
        let dialog = document.getElementById(dialogId);
        if (dialog == null) {
            console.error(`Dialog with id ${dialogId} not found`);
            resolve(false);
        }
        dialog.close();
        $('body').css("overflow", "auto");
        resolve(true);
    })
}

window.downloadFileFromStream = async (fileName, contentStreamReference) => {
    const arrayBuffer = await contentStreamReference.arrayBuffer();
    const blob = new Blob([arrayBuffer]);
    const url = URL.createObjectURL(blob);
    const anchorElement = document.createElement('a');
    anchorElement.href = url;
    anchorElement.download = fileName ?? '';
    anchorElement.click();
    anchorElement.remove();
    URL.revokeObjectURL(url);
}
/**
* Template Name: Nova - v1.3.0
* Template URL: https://bootstrapmade.com/nova-bootstrap-business-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/
document.addEventListener('DOMContentLoaded', () => {
    "use strict";

    /**
     * Preloader
     */
    const preloader = document.querySelector('#preloader');
    if (preloader) {
        window.addEventListener('load', () => {
            preloader.remove();
        });
    }

    /**
     * Sticky header on scroll
     */

    window.onscroll = function () { myFunction() };

    function myFunction() {
        const selectHeader = document.querySelector('.header');
        if (selectHeader) {
            window.scrollY > 100 ? selectHeader.classList.add('sticked') : selectHeader.classList.remove('sticked');
        }
    }
    /**
     * Mobile nav toggle
     */
    const mobileNavShow = document.querySelector('.mobile-nav-show');
    const mobileNavHide = document.querySelector('.mobile-nav-hide');

    document.querySelectorAll('.mobile-nav-toggle').forEach(el => {
        el.addEventListener('click', function (event) {
            event.preventDefault();
            mobileNavToogle();
        })
    });

    window.MyInterop = (function () {
        const _ShowMenu = function (ShowMenu) {
            if (ShowMenu)
                document.body.classList.add('mobile-nav-active');
            else
                document.body.classList.remove('mobile-nav-active');
        };

        return {
            ShowMenu: _ShowMenu
        };
    })();


    function mobileNavToogle() {
        querySelector('body').classList.toggle('mobile-nav-active');
        mobileNavShow.classList.toggle('d-none');
        mobileNavHide.classList.toggle('d-none');
    }

    /**
     * Toggle mobile nav dropdowns
     */
    const navDropdowns = document.querySelectorAll('.navbar .dropdown > a');

    navDropdowns.forEach(el => {
        el.addEventListener('click', function (event) {
            if (document.querySelector('.mobile-nav-active')) {
                event.preventDefault();
                this.classList.toggle('active');
                this.nextElementSibling.classList.toggle('dropdown-active');

                let dropDownIndicator = this.querySelector('.dropdown-indicator');
                dropDownIndicator.classList.toggle('bi-chevron-up');
                dropDownIndicator.classList.toggle('bi-chevron-down');
            }
        })
    });

    /**
     * Scroll top button
     */
    const scrollTop = document.querySelector('.scroll-top');
    if (scrollTop) {
        const togglescrollTop = function () {
            window.scrollY > 100 ? scrollTop.classList.add('active') : scrollTop.classList.remove('active');
        }

        window.addEventListener('load', togglescrollTop);
        document.addEventListener('scroll', togglescrollTop);
        scrollTop.addEventListener('click', window.scrollTo({
            top: 0,
            behavior: 'smooth'
        }));
    }

    /**
     * Initiate glightbox
     */
    const glightbox = GLightbox({
        selector: '.glightbox'
    });

    /**
     * Init swiper slider with 1 slide at once in desktop view
     */
    new Swiper('.slides-1', {
        speed: 600,
        loop: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false
        },
        slidesPerView: 'auto',
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        }
    });

    /**
     * Init swiper slider with 3 slides at once in desktop view
     */
    new Swiper('.slides-3', {
        speed: 600,
        loop: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false
        },
        slidesPerView: 'auto',
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 40
            },

            1200: {
                slidesPerView: 3,
            }
        }
    });





    /**
     * Porfolio isotope and filter
     */
    let portfolionIsotope = document.querySelector('.portfolio-isotope');

    if (portfolionIsotope) {

        let portfolioFilter = portfolionIsotope.getAttribute('data-portfolio-filter') ? portfolionIsotope.getAttribute('data-portfolio-filter') : '*';
        let portfolioLayout = portfolionIsotope.getAttribute('data-portfolio-layout') ? portfolionIsotope.getAttribute('data-portfolio-layout') : 'masonry';
        let portfolioSort = portfolionIsotope.getAttribute('data-portfolio-sort') ? portfolionIsotope.getAttribute('data-portfolio-sort') : 'original-order';

        window.addEventListener('load', () => {
            let portfolioIsotope = new Isotope(document.querySelector('.portfolio-container'), {
                itemSelector: '.portfolio-item',
                layoutMode: portfolioLayout,
                filter: portfolioFilter,
                sortBy: portfolioSort
            });

            let menuFilters = document.querySelectorAll('.portfolio-isotope .portfolio-flters li');
            menuFilters.forEach(function (el) {
                el.addEventListener('click', function () {
                    document.querySelector('.portfolio-isotope .portfolio-flters .filter-active').classList.remove('filter-active');
                    this.classList.add('filter-active');
                    portfolioIsotope.arrange({
                        filter: this.getAttribute('data-filter')
                    });
                    if (typeof aos_init === 'function') {
                        aos_init();
                    }
                }, false);
            });

        });

    }

    /**
     * Animation on scroll function and init
     */
    function aos_init() {
        AOS.init({
            duration: 800,
            easing: 'slide',
            once: true,
            mirror: false
        });
    }
    window.addEventListener('load', () => {
        aos_init();
    });

    window.BlazorDownloadFile = (fileName, fileContent) => {
        const blob = b64toBlob(fileContent);
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    function b64toBlob(base64) {
        const byteCharacters = atob(base64);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, { type: 'application/zip' });
    }
});