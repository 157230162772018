import * as L from 'leaflet';
import $ from 'jquery';

export class BoundaryAreaProvider {
    constructor(connectionString, layerGroup) {
        this.connectionString = connectionString;
        this.layerGroup = layerGroup;
    }

    getAllAsGeoJsonByProjectId(projectId) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `${this.connectionString}/api/BoundaryArea/GetAllAsGeoJsonByProjectId`,
                type: 'GET',
                data: {
                    projectId: projectId
                },
                success: (result) => {
                    resolve(JSON.parse(result));
                },
                error: (error) => {
                    reject(error);
                }
            });
        })
    }

    addToLayer(data) {
        data.features.forEach(feature => {

            let content = `<b>Name:</b> ${feature.properties.name} <br>
            <b>Description:</b> ${feature.properties.description}`;

            L.geoJson(feature, {
                style: function (feature) {
                    return {
                        stroke: true,
                        color: "green",
                        weight: 2,
                    }
                }
            })
            .bindPopup(content)
            .bindTooltip(content)
            .addTo(this.layerGroup);

        })
    }
}