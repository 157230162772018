import * as L from 'leaflet';
import $ from 'jquery';
import { DateTime } from "luxon";
import { CoordinatesHelper } from '../helpers/CoordinatesHelper';

export class HealthSafetyEnvironmentEventProvider {
    constructor(connectionString) {
        this.connectionString = connectionString;
    }

    getAllAsGeoJsonByProjectId(projectId, start, end) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `${this.connectionString}/api/HealthSafetyEnvironmentEvent/GetAllAsGeoJsonByProjectIdAndRange`,
                type: 'GET',
                data: {
                    projectId: projectId,
                    start: start,
                    end: end
                },
                success: (result) => {
                    resolve(JSON.parse(result));
                },
                error: (error) => {
                    reject(error);
                }
            });
        })
    }

    
    createTimelineItems(data) {
        let dataBuffer = [];
        data.features.forEach(function (feature) {
            if (dataBuffer.find(x => x.id === feature.properties.featureId)){
                return
            }

            let dateTime = DateTime.fromMillis(feature.properties.time[0]);
            dataBuffer.push({
                id: feature.properties.featureId,
                title: feature.properties.healthSafetyEnvironmentType,
                content: feature.properties.healthSafetyEnvironmentType,
                start: dateTime.toLocal().toRFC2822(),
                end: dateTime.toLocal().toRFC2822(),
                group: feature.properties.featureType,
                type: 'box'
            });
        });

        return dataBuffer;
    }
    
    static getMarkerOptions(feature) {

        let coords = CoordinatesHelper.latLngtoDecimalDegreesMinutesString(feature.geometry.coordinates[1], feature.geometry.coordinates[0])
        return {
            icon: L.divIcon({
                html:
                    `<svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43.97 41.9">
                        <defs>
                            <style>
                                .cls-1{fill:#1d1d1b;}
                            </style>
                        </defs>
                        <g id="Icon">
                            <g>
                                <path class="cls-1" d="m19.63,23.15H7.34c-.86,0-1.55.58-1.55,1.3s.7,1.3,1.55,1.3h12.3c.86,0,1.55-.58,1.55-1.3s-.7-1.3-1.55-1.3Z"/>
                                <path class="cls-1" d="m19.18,29.44H7.34c-.86,0-1.55.58-1.55,1.29s.7,1.29,1.55,1.29h11.84c.86,0,1.55-.58,1.55-1.29s-.7-1.29-1.55-1.29Z"/>
                                <path class="cls-1" d="m35.7,23.46l-6.5,5.6-1.93-1.68c-.54-.47-1.39-.45-1.91.03-.25.24-.38.54-.38.85,0,.32.14.64.41.88l2.85,2.48c.26.23.6.34.94.34.35,0,.71-.13.97-.37.02-.02.03-.04.04-.06.04-.03.08-.04.12-.07l7.24-6.23c.28-.24.42-.56.42-.88,0-.3-.12-.61-.37-.84-.52-.49-1.37-.5-1.91-.04Z"/>
                            </g>
                            <g>
                                <path d="m17.03,6.8s.03,0,.04,0h.04s.03,0,.04,0h13.25v3.1c0,.64.52,1.15,1.15,1.15s1.15-.52,1.15-1.15V1.15c0-.64-.52-1.15-1.15-1.15s-1.15.52-1.15,1.15v3.05h-13.33c-.71.01-1.29.59-1.29,1.3s.55,1.26,1.25,1.3Z"/>
                                <path class="cls-1" d="m38.73,4.21h-2.07s-.01,0-.02,0-.01,0-.02,0c-.71.01-1.29.59-1.29,1.3s.58,1.29,1.29,1.3c0,0,.01,0,.02,0s.01,0,.02,0h2.07c1.46,0,2.64,1.18,2.64,2.64v5.82H7.08c-.7.03-1.25.6-1.25,1.3s.56,1.27,1.25,1.3h34.29v18.79c0,1.46-1.19,2.65-2.64,2.65H5.24c-1.46,0-2.64-1.19-2.64-2.65V9.47c0-1.46,1.19-2.65,2.64-2.65h5.75v3.08c0,.64.52,1.15,1.15,1.15s1.15-.52,1.15-1.15V1.15c0-.64-.52-1.15-1.15-1.15s-1.15.52-1.15,1.15v3.08h-5.75C2.35,4.23,0,6.58,0,9.47v27.18c0,2.89,2.35,5.25,5.24,5.25h33.48c2.89,0,5.24-2.35,5.24-5.25V9.45c0-2.89-2.35-5.24-5.24-5.24Z"/>
                            </g>
                        </g>
                    </svg>
            `,
                //shadowUrl: 'leaf-shadow.png',
                className: "rotated-marker",
                style: "transform-origin: center;",
                iconSize: [20, 30], // size of the icon
                //shadowSize:   [50, 64], // size of the shadow
                iconAnchor: [10, 12], // point of the icon which will correspond to marker's location
                //shadowAnchor: [4, 62],  // the same for the shadow
                popupAnchor: [0, -10] // point from which the popup should open relative to the iconAnchor
            }),
            popupContent: `<b>${feature.properties.healthSafetyEnvironmentType} ${DateTime.fromMillis(feature.properties.time[0], { zone: 'utc'}).toLocal().toLocaleString(DateTime.DATETIME_SHORT)}</b><br>
                           <b>Description:</b> ${feature.properties.description}<br>
                           <b>Logged By:</b> <a href="https://www.marinetraffic.com/en/ais/details/ships/${feature.properties.loggedByMmsi}" target="_blank">${feature.properties.loggedByMmsi}</a><br>
                           <b>Date:</b> ${DateTime.fromMillis(feature.properties.time[0], { zone: 'utc' }).toLocal().toLocaleString(DateTime.DATETIME_SHORT)}<br>
                           <b>Coordinates:</b>
                           <br>
                           <ul>
                                <li>
                                    <b>Latitude:</b> ${coords.lat}
                                </li>
                                <li>
                                    <b>Longitude:</b> ${coords.lng}
                                </li>
                            </ul>`,
            tooltipContent: `${feature.properties.healthSafetyEnvironmentType}`
        };    
    }
}